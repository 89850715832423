<template>
	<ValidationObserver ref="obs" tag="form">
		<v-card class="elevation-12" id="login-card" slot-scope="{ invalid }">
			<v-toolbar dark color="primary" flat>
				<v-toolbar-title>
					{{ $t("auth.loginUsingBankId") }}
				</v-toolbar-title>
				<v-spacer />
				<language-picker
					:userIsAuthenticated="false"
					:resetFieldsAfterChange="[$refs.obs]"
				></language-picker>
			</v-toolbar>
			<v-card-text>
				<div class="my-2 d-flex flex-column align-center">
					<v-icon x-large class="mt-2 mb-4"> $vuetify.icons.bankid </v-icon>
					<div
						class="d-flex flex-column align-center"
						v-if="polling"
					>
                        <v-row
							class="my-2 text-center"
							align-content="center"
							align="center"
							justify="center"
						>
							<div v-html="status"></div>
						</v-row>

						<canvas v-if="showCanvas" id="qr-code-container" ref="container"></canvas>
                        <v-progress-circular
                            v-else
                            :size="20"
                            color="primary"
                            class="mr-2"
                            indeterminate
                        ></v-progress-circular>

						<v-btn color="error" :disabled="canceled" class="my-2" @click.prevent="prepareCancel"
							>Avbryt</v-btn
						>
					</div>

					<v-progress-circular
						v-else-if="loading && !polling"
						:size="20"
						color="primary"
						class="mr-2"
						indeterminate
					></v-progress-circular>

					<div
						class="d-flex flex-column align-center w-100"
						id="login-options"
						v-else
					>
						<validation-provider
							:name="$t('auth.personalNumberLabel')"
							rules="required|digits:12"
						>
							<v-text-field
								solo
								clearable
								:hide-details="!invalid"
								:label="$t('auth.personalNumberInputLabel')"
								type="tel"
								v-model="personalNumber"
								slot-scope="{ errors }"
								:error-messages="errors"
								:disabled="loading"
								:loading="loading"
								class="mb-2"
							></v-text-field>
						</validation-provider>
						<v-btn
							class="my-2 text-none"
							color="primary"
							type="submit"
							:loading="loading"
							:disabled="loading"
							@click.prevent="startTransaction(false)"
						>
							Mobilt BankID QR-kod
						</v-btn>
						<v-btn
							v-if="isMobile"
							class="my-2 text-none"
							color="primary"
							:loading="loading"
							:disabled="loading"
							@click.prevent="startTransaction(true)"
						>
							Mobilt BankID på denna enhet
						</v-btn>
                        <v-btn
							v-if="!isMobile"
							class="my-2 text-none"
							color="primary"
							:loading="loading"
							:disabled="loading"
							@click.prevent="startTransaction(true)"
						>
							BankID på dator
						</v-btn>
					</div>
				</div>
			</v-card-text>
		</v-card>
	</ValidationObserver>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import QRCode from "qrcode";
import isMobileJs from "ismobilejs";
import LanguagePicker from "../LanguagePicker.vue";
export default {
	data() {
		return {
			personalNumber: null,
			loading: false,
			polling: false,
			status: "",
			canceled: false,
			transactionId: null,
			isMobile: false,
			startOnSameDevice: false,
            showCanvas: true,
		};
	},

	components: {
		ValidationProvider,
		ValidationObserver,
		LanguagePicker,
	},

	methods: {
		generateQr(data) {
			this.$refs.container.innerHTML = "";
			QRCode.toCanvas(this.$refs.container, data, {
				errorCorrectionLevel: "M",
				width: 256,
			});
		},

        launchApp(autoStartToken) {
            this.showCanvas = false;
            const url = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
            if (!this.isMobile) {
                return this.launchDesktopApp(url);
            }

            window.location.href = url;
		},

        launchDesktopApp(url) {
            const frame = document.createElement('iframe');
            frame.src = url;
            frame.id = "bankid-desktop-frame";
            frame.height = 0;
            frame.width = 0;
            frame.style.visibility = "hidden";
            frame.style.position = "fixed";
            document.body.appendChild(frame);
        },

        prepareCancel(){
            /** Prepare to cancel, if we have an ongoing poll request we want to cancel after that one. */
            this.status = `<p>Avbryter...</p>`;
            this.canceled = true;
        },

		cancel() {
			axios
				.post(this.route("login-bankid.cancel").toString(), {
					transaction_id: this.transactionId,
				})
				.then((res) => {
					console.debug("Transaction cancelled", res);
				})
				.catch((err) => {
					console.error("error canceling transaction: ", err);
				})
                .then(() => {
                    this.reset("");
                })
		},

		async startTransaction(startOnDevice = false) {
			let valid = await this.$refs.obs.validate();

			if (!valid) return;

			this.startOnSameDevice = startOnDevice;
            this.loading = true;
			let locale = this.$store.getters.getUserLocale();

			axios
				.post(
					this.route("login-bankid.request").toString(),
					{
						personal_number: this.personalNumber,
					},
					{
						headers: {
							"Accept-language": locale,
						},
					}
				)
				.then((res) => {
					if (res.data.success === false) {
						this.$store.commit("snack/SET", {
							message: res.data.message,
							color: "error",
						});
						throw new Error();
					}
					window.onbeforeunload = () => "";

					this.transactionId = res.data.transaction_id;
                    this.polling = true;

					if (this.startOnSameDevice === true) {
						this.launchApp(res.data.auto_start_token);
					} else {
                        /// bankid best practice text
                        this.status = `
                            <p>1. Starta BankID-appen på din mobil eller surfplatta.</p>

                            <p>2. Klicka på knappen ”Skanna QR-kod” inuti appen.</p>

                            <p>3. Skanna QR-koden som visas här nedan.</p>
                        `;
                        this.showCanvas = true;
						this.$nextTick().then(() => {
							this.generateQr(res.data.qr_data);
						});
					}

					this.delay(2000).then(() => {
						this.pollTransaction();
					});
				})
				.catch((err) => {
					console.error("err: ", err);
					this.reset("");
					this.$store.commit("snack/SET", {
						message: _.get(
							err,
							"response.data.message",
							this.$t("common.somethingWentWrong")
						),
						color: "error",
					});
				});
		},

		delay(ms) {
			return new Promise((resolve) => setTimeout(resolve, ms));
		},

		reset(statusMessage) {
            if (this.$refs.container) {
			    this.$refs.container.innerHTML = "";
            }
			this.loading = false;
            this.polling = false;
			this.status = statusMessage;
            this.canceled = false;
		},

		pollTransaction() {
            if (this.canceled) {
                return this.cancel();
            }
			axios
				.post(this.route("login-bankid.collect").toString(), {
					transaction_id: this.transactionId,
				})
				.then(async (res) => {
					console.log(res);
					if (res.data.progressStatus === "COMPLETE") {
						this.polling = false;
						this.status = this.$t("auth.loginSuccess");
						window.onbeforeunload = null;
						return window.location.reload();
					} else if (res.data.progressStatus === "FAILED") {
						this.reset(res.data.message);
						this.$store.commit("snack/SET", {
							message: res.data.message,
							color: "error",
							timeout: 0,
						});
						return;
					}

					if (!this.startOnSameDevice) {
						this.generateQr(res.data.qr_data);
					}

					if (res.data.message) {
                        // overwrite default message when using QR-codes.
                        // https://www.bankid.com/utvecklare/guider/praxis/praxis-dator-mobil
                        if (res.data.message !== 'Starta BankID-appen') {
						    this.status = res.data.message;
                        }
					}
					await this.delay(2000);
                    return this.pollTransaction();
				})
				.catch((err) => {
					if (err.response.status === 300) {
						window.onbeforeunload = null;
						// we need a redirect, this should be on the global axios instance
						window.location.href = err.response.headers.location;
						return;
					}
					if (!err.response) {
							// Network error, no response from server.
							console.error('Possible network error, retrying poll', err);
							this.delay(1500).then(() => {
									return this.pollTransaction();
							})
					}
					this.$store.commit("snack/SET", {
						message: err.response.data.message,
						color: "error",
						timeout: 0,
					});

					this.reset("");
				});
		},
	},

	mounted() {
		this.isMobile = isMobileJs(navigator.userAgent).any;
	},
};
</script>
<style scoped>
#login-options > * {
	width: 100%;
	max-width: 100%;
}
.w-100 {
	width: 100%;
}
</style>